<template>
  <CRow>
    <CCol col="12">
      <Toast ref="mensajeToast"></Toast>
      <Alerts ref="alert"></Alerts>
      <ModalPDF ref="visorPDF"></ModalPDF>
      <Confirm ref="confirm"></Confirm>
      <ModalVideo ref="modalVideo"></ModalVideo>
      <transition name="slide">
        <CCard class="border-warning">
          <CCardHeader>
            <strong>Historial Académico por Estudiante</strong>
            <div class=" card-header-actions">
              <form class="form-inline">
                <input id="user" type="hidden" value="<?php echo $user->id ?>"/>
                <b>Filas por p&aacute;gina</b>
                <select class="form-control mr-2 ml-1" @change="sizeChange($event)"
                        v-model="filasPorPagina">
                  <option v-for="option in sizeoptions" :value="option.value">
                    {{ option.text }}
                  </option>
                </select>

                <button class="btn btn-outline-dark mr-2" type="button"
                        @click="borrarFiltroBtnOnClick()">
                  <font-awesome-icon icon="fa-sort-amount-up-alt"/>
                  Quitar Filtro
                </button>

                <button class="btn btn-outline-success ml-2" data-html="true"
                        data-toggle="tooltip"
                        title="Para exportar todos los datos en un archivo Excel<br/>seleccione la opci&oacute;n <u>Todos</u> en <b>Filas por p&aacute;gina</b>"
                        type="button"
                        @click="excelBtnOnClick()">
                  <font-awesome-icon icon="fa-file-excel"/>
                  Exportar a Excel
                </button>


                <CDropdown
                    inNav
                    class="c-header-nav-items"
                    placement="bottom-end"
                    add-menu-classes="pt-0"
                    :caret="false"
                >
                  <template #toggler>
                    <CHeaderNavLink title="Ver videos de ayuda">
                      <font-awesome-icon icon="fas fa-question-circle" size="2x"/>
                    </CHeaderNavLink>
                  </template>
                  <CDropdownItem>
                    <a href="#" @click="$refs.modalVideo.mostrarVideo('video-alumno-hoja-ruta.mp4')"
                       class="text-info" title="Ver video de ayuda">
                      <font-awesome-icon icon="fas fa-video"/>
                      Ayuda (Hoja ruta)
                    </a>
                  </CDropdownItem>
                  <CDropdownItem>
                    <a href="#" @click="$refs.modalVideo.mostrarVideo('video-alumno-eliminar-nuevo.mp4')"
                       class="text text-danger" title="Ver video de ayuda">
                      <font-awesome-icon icon="fas fa-video"/>
                      Ayuda (Eliminar)
                    </a>
                  </CDropdownItem>
                </CDropdown>
              </form>
            </div>
          </CCardHeader>
          <CCardBody class="p-0 pr-1">
            <JqxGrid :width="'100%'" ref="gridSystem"
                     :source="dataAdapter"
                     :autoheight="true" :autorowheight="false" :rowsheight="50"
                     :pageable="true"
                     :pagesize="parseInt(filasPorPagina)"
                     :virtualmode="true"
                     :rendergridrows="rendergridrows"
                     :columns="columns" :enablebrowserselection="true"
                     :enabletooltips="true" :filterable="true"
                     :showfilterrow="true"
                     :sortable="true"
                     :pagermode="'simple'" :localization="localization"
                     :theme="'bootstrap'" :columnsresize="true"/>
          </CCardBody>
        </CCard>
      </transition>
    </CCol>
    <CModal
        :centered="true"
        :closeOnBackdrop="false"
        :fade="true"
        :no-close-on-backdrop="true"
        :show.sync="modalHistorial"
        size="xl"
    >
      <template #body-wrapper>
        <div class="row pl-2">
          <div class="col-2 text-center">
            <template v-if="historiales.length">
              <h5 class="text-center"><em class="text-danger">Seleccionar Historial SERIE N°:</em></h5>
              <ul class="list-group">
                <template v-for="historial in historiales">
                  <li :class="'list-group-item'+(historialseleccionado===historial.serie?' list-group-item-success':'')">
                    <input class="form-check-input me-1" type="radio" name="listGroupGestion"
                           :id="'fr'+historial.serie" :checked="historialseleccionado===historial.serie"
                           @click="historialseleccionado=historial.serie;mostrarHistorial(historial)">
                    <label class="form-check-label" :for="'fr'+historial.serie">{{ historial.serie }}
                    </label>
                  </li>
                </template>
              </ul>
              <hr/>
              <div class="row">
                <form ref="frmFactura" @submit.prevent="validaFactura()" novalidate>
                <div class="col">
                <label>Número de Factura</label>
                <input type="number" v-model="factura_id" class="form-control" required="required" min="1">
                  <div class="invalid-feedback">
                    El númeor de factura es requerido
                  </div>
                </div>
                <div class="col my-3">
                  <button type="submit" class="btn btn-success">
                    Generar Historial Académico
                  </button>
                </div>
                </form>
              </div>
            </template>
            <template v-else>No existe un historial registrado<br/>
              <button type="button" class="btn btn-success" @click="nuevoHistorial('0')">
                Generar Historial Académico
              </button>
            </template>
            <p v-if="historialaprobadas!==historialmaterias">
              El historial Académico no muestra el número de serie
              porque el estudiante aprobó <strong class="text-danger">{{ historialaprobadas }}</strong> materias de
              <strong class="text-primary">{{ historialmaterias }}</strong>
            </p>
          </div>
          <div class="col-10">
            <embed ref="reportePdf" height="550" src="" type="application/pdf" title="pdf.pdf"
                   width="100%"/>
          </div>
        </div>
      </template>
      <template #header>
        <h4>{{ alumno }}</h4>
        <CButtonClose @click="modalHistorial = false"/>
      </template>
      <template #footer-wrapper><span></span></template>
    </CModal>
  </CRow>
</template>
<script>
import JqxGrid from "jqwidgets-scripts/jqwidgets-vue/vue_jqxgrid";
import jqxInput from "jqwidgets-scripts/jqwidgets-vue/vue_jqxinput";
import Toast from "@/views/notifications/Toast";
import Alerts from "@/views/notifications/Alerts";
import ModalPDF from "@/views/notifications/ModalPDF";
import Confirm from "@/views/notifications/Confirm";
import ModalVideo from "@/views/notifications/ModalVideo";
import axios from "axios";

var sourceLst;
var address;
var modalPdf;
var pagesizeoptions = [6, 10, 50, 100, 500, 1000, 5000, 10000];
var pagesize = pagesizeoptions[0];

export default {
  name: 'Alumnos',
  components: {
    ModalVideo,
    Confirm,
    ModalPDF,
    Alerts,
    Toast,
    JqxGrid,
    jqxInput
  },
  data: () => {
    return {
      darkModal: false,
      modalHistorial: false,
      historiales: [],
      codigo_alumno: '',
      alumno: '',
      historialseleccionado: '',
      historialaprobadas: 0,
      historialmaterias: 0,
      factura_id:0,
      items: [],
      currentPage: 1,
      perPage: 7,
      totalRows: 0,
      filasPorPagina: pagesizeoptions[0],
      sizeoptions: [],
      sedeSeleccionada: localStorage.sedeSeleccionada,
      dataAdapter: new jqx.dataAdapter(sourceLst, {
        loadError: function (xhr, status, error) {
          console.log("Error ocurrido al obtener los datos del servidor, " + status + ", " + error);
          location.href = location.protocol + "//" + location.host + "/#/login";
        }
      }),
      rendergridrows: (params) => {
        return params.data;
      },
      columns: [
        {
          text: 'Código Alumno', datafield: "codigo_alumno", width: '7%', cellsalign: 'center',
          renderer: function () {
            return '<div style="margin-top: 5px; margin-left: 5px;">Código<br />Alumno</div>';
          },
          createfilterwidget: function (column, columnElement, widget) {
            widget.jqxInput({width: '100%', height: 27, placeHolder: "Buscar Código Alumno"});
          }
        },
        {
          text: 'Apellido1', datafield: "apellido1", width: '12%',
          renderer: function () {
            return '<div style="margin-top: 5px; margin-left: 5px;">Primer<br />apellido</div>';
          },
          createfilterwidget: function (column, columnElement, widget) {
            widget.jqxInput({width: '100%', height: 27, placeHolder: "Buscar Apellido1"});
          }
        },
        {
          text: 'Apellido2', datafield: "apellido2", width: '12%',
          renderer: function () {
            return '<div style="margin-top: 5px; margin-left: 5px;">Segundo<br />apellido</div>';
          },
          createfilterwidget: function (column, columnElement, widget) {
            widget.jqxInput({width: '100%', height: 27, placeHolder: "Buscar Apellido2"});
          }
        },
        {
          text: 'Nombres', datafield: "nombres", width: '17%',
          createfilterwidget: function (column, columnElement, widget) {
            widget.jqxInput({width: '100%', height: 27, placeHolder: "Buscar Nombres"});
          }
        },
        {
          text: 'Carrera', datafield: "carrera", width: '24%',
          createfilterwidget: function (column, columnElement, widget) {
            widget.jqxInput({width: '100%', height: 27, placeHolder: "Buscar Carrera"});
          }
        },
        {
          text: 'Nro. Serie', datafield: "serie", width: '13%',
          renderer: function () {
            return '<div style="margin-top: 5px; margin-left: 5px;">Nro. Serie<br />Historial Acad.</div>';
          },
          createfilterwidget: function (column, columnElement, widget) {
            widget.jqxInput({width: '100%', height: 27, placeHolder: "Buscar Carrera"});
          }
        },
        {
          text: "", width: '15%', columntype: "button",
          filterable: false, sortable: false,
          cellclassname: "btn-green",
          renderer: function () {
            return '';
          },
          cellsrenderer: function (index, datafield, value, defaultvalue, column, rowdata) {
            return ((rowdata.estado === 'Y') ? "Historial Académico" : "...");
          },
          buttonclick: function (row, event) {
            let button = $(event.currentTarget);
            let grid = button.parents("[id^=jqxGrid]");
            let rowData = grid.jqxGrid('getrowdata', row);
            modalPdf.$refs.reportePdf.setAttribute('src', '')
            modalPdf.codigo_alumno = rowData.codigo_alumno;
            modalPdf.alumno = rowData.nombres + ' ' + rowData.apellido1 + ' ' + rowData.apellido2;
            modalPdf.modalHistorial = true;
            modalPdf.gestionseleccionada = ''
            axios.get(
                address + '/api/docentereportes/' + rowData.codigo_alumno + '/historiales?token=' + localStorage.getItem("api_token")
            )
                .then((response) => {
                  modalPdf.historiales = response.data;
                  modalPdf.factura_id=0;
                  if (modalPdf.historiales.length === 1) {
                    for (let i = 0; i < modalPdf.historiales.length; i++) {
                      modalPdf.historialseleccionado = modalPdf.historiales[i].serie;
                      modalPdf.mostrarHistorial(modalPdf.historiales[i])
                      break;
                    }
                  }
                })
                .catch(function (error) {
                  modalPdf.$refs.mensajeToast.mostrarError(error, modalPdf);
                });
          }
        },
      ],
      localization: {
        'emptydatastring': "No existen datos para esta SEDE",
      },
    }
  },
  beforeCreate: function () {
    const url = this.$apiAdress + '/api/docentereportes/boletin?token=' + localStorage.getItem("api_token") + '&sede_id=' + localStorage.sedeSeleccionada;
    address = this.$apiAdress;
    sourceLst = {
      url: url,
      datafields: [
        {name: 'id', type: "int"},
        {name: 'codigo_alumno', type: "string"},
        {name: 'apellido1', type: "string"},
        {name: 'apellido2', type: "string"},
        {name: 'nombres', type: "string"},
        {name: 'carrera', type: "string"},
        {name: 'serie', type: "string"},
        {name: 'estado', type: "string"},
      ],
      data: {
        sede: this.sede
      },
      datatype: "json",
      root: "Rows",
      id: 'codigo_alumno',
      sortcolumn: 'codigo_alumno',
      sortdirection: 'desc',
      cache: false,
      beforeprocessing: (data) => {
        if (data != null) {
          sourceLst.totalrecords = data[0].TotalRows;
          let i = 0;
          this.sizeoptions = [];
          while (i < 8 && pagesizeoptions[i] < data[0].TotalRows) {
            this.sizeoptions[i] = {text: pagesizeoptions[i], value: pagesizeoptions[i]};
            i++;
          }
          if (i > 1) {
            this.sizeoptions[i] = {text: "TODOS", value: data[0].TotalRows};
          }
        }
        modalPdf.$refs.alert.hide();
      },
      filter: () => {
        this.$refs.gridSystem.updatebounddata("filter");
      },
      sort: () => {
        this.$refs.gridSystem.updatebounddata("sort");
      },
    };
  },
  methods: {
    hasRole(roleUser) {
      let roles = localStorage.getItem("roles");
      if (roles != null) {
        roles = roles.split(',')
        if (roles != null && roles.indexOf(roleUser) >= 0) {
          return true
        }
      }
      return false;
    },
    showHideColumn(role) {
      try {
        if (!modalPdf.hasRole(role)) {
          this.$refs.gridSystem.hidecolumn(role);
        }
      } catch (e) {
      }
    },
    excelBtnOnClick: function () {
      this.$refs.gridSystem.hidecolumn('documentos');
      this.$refs.gridSystem.hidecolumn('editar');
      this.$refs.gridSystem.hidecolumn('eliminar');
      let date = new Date();
      let dateStr =
          date.getFullYear() + "" +
          ("00" + (date.getMonth() + 1)).slice(-2) + "" +
          ("00" + date.getDate()).slice(-2) + "_" +
          ("00" + date.getHours()).slice(-2) + "" +
          ("00" + date.getMinutes()).slice(-2) + "" +
          ("00" + date.getSeconds()).slice(-2);
      this.$refs.gridSystem.exportdata('xls', 'Inscritos' + dateStr, true, null, true, modalPdf.$apiAdress + "/api/reporte/exportarxls/" + '?token=' + localStorage.getItem("api_token"));
      this.$refs.gridSystem.showcolumn('documentos');
      this.$refs.gridSystem.showcolumn('editar');
      this.$refs.gridSystem.showcolumn('eliminar');
    },
    borrarFiltroBtnOnClick: function () {
      this.$refs.gridSystem.clearfilters();
    },
    sizeChange: function (event) {
      try {
        modalPdf.$refs.alert.show("Actualizando datos");
        this.$refs.gridSystem.pagesize = parseInt(this.filasPorPagina);
        this.$refs.gridSystem.updatebounddata();
      } catch (e) {}
    },
    listarhistoriales: function (codigo_alumno) {
      axios.get(
          address + '/api/docentereportes/' + codigo_alumno + '/historiales?token=' + localStorage.getItem("api_token")
      )
          .then((response) => {
            modalPdf.historiales = response.data;
            if (modalPdf.historiales.length > 0) {
              modalPdf.historialaprobadas = modalPdf.historiales[0].materiasaprobadas[0].aprobadas
              modalPdf.historialmaterias = modalPdf.historiales[0].materiasaprobadas[0].materias
            }
          })
          .catch(function (error) {
            modalPdf.$refs.mensajeToast.mostrarError(error, modalPdf);
          });
    },
    validaFactura:function () {
      if (!modalPdf.$refs.frmFactura.checkValidity()) {
        modalPdf.$refs.frmFactura.classList.add('was-validated');
      } else {
        modalPdf.nuevoHistorial('0');
      }
    },
    nuevoHistorial: function (serie) {
      modalPdf.modalHistorial = false;
      modalPdf.$refs.confirm.confirm('', '¿Crear nuevo Historial de Académico para ' + modalPdf.alumno + '?', function () {
        modalPdf.modalHistorial = true;
        modalPdf.mostrarCrearHistorial(serie)
      }, function () {
        modalPdf.modalHistorial = true;
      })
    },
    mostrarHistorial: function (historial) {
      if (historial !== '') {
        let serie = historial.serie
        modalPdf.historialaprobadas = historial.materiasaprobadas[0].aprobadas
        modalPdf.historialmaterias = historial.materiasaprobadas[0].materias
        modalPdf.mostrarCrearHistorial(serie)
      }
    },
    mostrarCrearHistorial: function (serie) {
      if (serie !== '') {
        modalPdf.modalHistorial = false;
        modalPdf.$refs.reportePdf.setAttribute('src', '')
        modalPdf.$refs.alert.show('Descargando documento');
        axios.get(
            address + '/api/docentereportes/' + modalPdf.codigo_alumno + '/' + serie + '/' + modalPdf.factura_id + '/historialacademicopdf?token=' + localStorage.getItem("api_token"),
            {responseType: 'blob'}
        )
            .then((response) => {
              modalPdf.$refs.reportePdf.setAttribute('src', URL.createObjectURL(response.data) + "#view=FitH");
              modalPdf.listarhistoriales(modalPdf.codigo_alumno)
            })
            .catch(function (error) {
              modalPdf.$refs.mensajeToast.mostrarError(error, modalPdf);
            })
            .finally(function () {
              modalPdf.$refs.alert.hide();
              modalPdf.modalHistorial = true;
            })
      }
    }
  },
  mounted() {
    modalPdf = this;
    this.$root.$on('sedeSeleccionada', data => {
      left.sede = data;
    });
    modalPdf.showHideColumn('documentos')
    modalPdf.showHideColumn('compromisos');
    modalPdf.showHideColumn('contratos');
    modalPdf.showHideColumn('hojaruta');
  },
}
</script>